import { AppBar, makeStyles, Typography } from "@material-ui/core";
import { FunctionComponent } from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "transparent",
    border: "none",
    top: "auto",
    bottom: 0,
    boxShadow: "none",
    color: theme.palette.text.primary,
    padding: theme.spacing(2),
    zIndex: 0,
  },
}));

const Footer: FunctionComponent = () => {
  const classes = useStyles();
  return (
    <AppBar className={classes.root} position="fixed">
      <Typography align="center">
        Having an issue? Message <Link to="/users/thought">the creator</Link>!
      </Typography>
    </AppBar>
  );
};

export default Footer;
