import React from "react";
import useCurrentUsername from "hooks/useCurrentUsername";
import { useGetCurrentUserQuery } from "queries/autogenerate/hooks";
import UserCard from "components/UserCard";
import CreateProfile from "components/CreateProfile";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { Button, Typography } from "@material-ui/core";
import LocationCard from "components/LocationCard";
import LoadingScreen from "components/LoadingScreen";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  btnContainer: {
    display: "flex",
    marginTop: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(1.5),
  },
  signOutBtn: {
    marginLeft: "auto",
  },
  help: {
    marginTop: theme.spacing(2),
  },
}));

const CurrentUser: React.FunctionComponent = () => {
  const classes = useStyles();
  const username = useCurrentUsername();

  const { data, loading } = useGetCurrentUserQuery({
    skip: !username,
    variables: { username: username! },
  });

  if (loading || !username) {
    return <LoadingScreen />;
  }

  return (
    <Container className={classes.root}>
      {data?.user ? (
        <>
          <Typography className={classes.title} variant="h4">
            Profile
          </Typography>
          <UserCard edit user={data.user} />
          <LocationCard username={username} />
        </>
      ) : (
        <CreateProfile username={username} />
      )}
      <div className={classes.btnContainer}>
        <Button
          component={Link}
          to="/signout"
          variant="contained"
          className={classes.signOutBtn}
          size="large"
        >
          Sign out
        </Button>
      </div>
    </Container>
  );
};

export default CurrentUser;
