import { FormEvent, FunctionComponent, useState } from "react";
import { DatePicker } from "@material-ui/pickers";
import {
  GetUserDocument,
  useCreateProfileMutation,
} from "queries/autogenerate/hooks";
import { Gender } from "queries/autogenerate/schemas";
import { Moment } from "moment";
import {
  Button,
  Radio,
  RadioGroup,
  FormLabel,
  FormControl,
  FormControlLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  GetUserQuery,
  GetUserQueryVariables,
} from "queries/autogenerate/operations";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(1.5),
  },
  dateInput: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  formElement: {
    display: "block",
    marginBottom: theme.spacing(1),
  },
  radio: {},
  button: {
    marginTop: theme.spacing(1),
  },
}));

export interface Props {
  username: string;
}

const CreateProfile: FunctionComponent<Props> = ({ username }) => {
  const [createProfileMutation] = useCreateProfileMutation();
  const [description, setDescription] = useState<string>("");
  const [birthday, setBirthday] = useState<Moment | null>(null);
  const [gender, setGender] = useState<Gender | null>(null);
  const classes = useStyles();

  const isDisabled = () => {
    return !birthday || !gender;
  };
  const handleSubmit = async (evt: FormEvent) => {
    evt.preventDefault();
    await createProfileMutation({
      variables: {
        input: {
          user: {
            gender: gender!,
            birthday: birthday!.format("YYYY-MM-DD"),
            ...(description && { description }),
          },
        },
      },
      update: (proxy, { data }) => {
        if (!data?.createUser.user) {
          return;
        }
        proxy.writeQuery<GetUserQuery, GetUserQueryVariables>({
          query: GetUserDocument,
          variables: {
            username,
          },
          data: {
            user: {
              ...data.createUser.user,
              isBlocked: false,
            },
          },
        });
      },
    });

    setBirthday(null);
    setDescription("");
    setGender(null);
  };

  return (
    <div>
      <Typography variant="h4" className={classes.title}>
        Create a Profile
      </Typography>
      <form onSubmit={handleSubmit}>
        <div className={classes.formElement}>
          <FormLabel component="legend">Birthday</FormLabel>
          <DatePicker
            disableFuture
            openTo="year"
            format="MM/DD/YYYY"
            views={["year", "month", "date"]}
            value={birthday}
            placeholder="MM/DD/YYYY"
            onChange={(newDate) => setBirthday(newDate)}
            className={classes.dateInput}
          />
        </div>
        <FormControl component="fieldset" className={classes.formElement}>
          <FormLabel component="legend">Gender</FormLabel>
          <RadioGroup
            aria-label="gender"
            name="gender"
            value={gender}
            onChange={(e) => setGender(e.target.value as Gender)}
          >
            <FormControlLabel
              value={Gender.Female}
              control={<Radio className={classes.radio} size="small" />}
              label="Female"
            />
            <FormControlLabel
              value={Gender.Male}
              control={<Radio className={classes.radio} size="small" />}
              label="Male"
            />
          </RadioGroup>
        </FormControl>
        <div className={classes.formElement}>
          <FormLabel component="legend">Description</FormLabel>
          <TextField
            multiline
            fullWidth
            value={description}
            placeholder="Say something about yourself"
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <Button
          disabled={isDisabled()}
          className={classes.button}
          type="submit"
          variant="contained"
          color="primary"
          size="large"
        >
          Create Profile
        </Button>
      </form>
    </div>
  );
};

export default CreateProfile;
