import { FunctionComponent, ReactNode, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
} from "react-router-dom";
import { Auth } from "aws-amplify";
import CurrentUser from "./screens/CurrentUser";
import FindUsers from "./screens/FindUsers";
import Conversations from "./screens/Conversations";
import User from "./screens/User";
import Messages from "./screens/Messages";

const SignOut: FunctionComponent = () => {
  const history = useHistory();
  useEffect(() => {
    (async () => await Auth.signOut())();
  });

  history.push("/");
  return null;
};

export interface Props {
  header: ReactNode;
  footer?: ReactNode;
}

const AppRouter: FunctionComponent<Props> = ({ header, footer }) => {
  return (
    <Router>
      {header}
      <Switch>
        <Route path="/me">
          <CurrentUser />
        </Route>
        <Route path="/users" exact>
          <FindUsers />
        </Route>
        <Route path="/users/:id" exact>
          <User />
        </Route>
        <Route path="/messages/:users" exact>
          <Messages />
        </Route>
        <Route path="/messages" exact>
          <Conversations />
        </Route>
        <Route path="/signout">
          <SignOut />
        </Route>
        <Route exact path="/">
          <Redirect to="/me" />
        </Route>
      </Switch>
      {footer}
    </Router>
  );
};

export default AppRouter;
