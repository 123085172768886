import * as Types from "./operations";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export const UserCard_UserFragmentDoc = gql`
  fragment UserCard_User on User {
    id
    age
    gender
    description
  }
`;
export const ConversationsScreen_ConversationFragmentDoc = gql`
  fragment ConversationsScreen_Conversation on Conversation {
    id
    users {
      id
      gender
    }
    latestMessage {
      id
      text
      createdAt
      user {
        id
      }
    }
  }
`;
export const MessagesScreen_MessageFragmentDoc = gql`
  fragment MessagesScreen_Message on Message {
    id
    user {
      id
      gender
    }
    text
    createdAt
  }
`;
export const BlockButton_UserDocument = gql`
  query BlockButton_User($username: ID!) {
    user(id: $username) {
      id
      isBlocked
    }
  }
`;

/**
 * __useBlockButton_UserQuery__
 *
 * To run a query within a React component, call `useBlockButton_UserQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlockButton_UserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlockButton_UserQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useBlockButton_UserQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.BlockButton_UserQuery,
    Types.BlockButton_UserQueryVariables
  >
) {
  return Apollo.useQuery<
    Types.BlockButton_UserQuery,
    Types.BlockButton_UserQueryVariables
  >(BlockButton_UserDocument, baseOptions);
}
export function useBlockButton_UserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.BlockButton_UserQuery,
    Types.BlockButton_UserQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    Types.BlockButton_UserQuery,
    Types.BlockButton_UserQueryVariables
  >(BlockButton_UserDocument, baseOptions);
}
export type BlockButton_UserQueryHookResult = ReturnType<
  typeof useBlockButton_UserQuery
>;
export type BlockButton_UserLazyQueryHookResult = ReturnType<
  typeof useBlockButton_UserLazyQuery
>;
export type BlockButton_UserQueryResult = Apollo.QueryResult<
  Types.BlockButton_UserQuery,
  Types.BlockButton_UserQueryVariables
>;
export const BlockUserDocument = gql`
  mutation BlockUser($input: BlockUserInput!) {
    blockUser(input: $input) {
      user {
        id
        isBlocked
      }
    }
  }
`;
export type BlockUserMutationFn = Apollo.MutationFunction<
  Types.BlockUserMutation,
  Types.BlockUserMutationVariables
>;

/**
 * __useBlockUserMutation__
 *
 * To run a mutation, you first call `useBlockUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockUserMutation, { data, loading, error }] = useBlockUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBlockUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.BlockUserMutation,
    Types.BlockUserMutationVariables
  >
) {
  return Apollo.useMutation<
    Types.BlockUserMutation,
    Types.BlockUserMutationVariables
  >(BlockUserDocument, baseOptions);
}
export type BlockUserMutationHookResult = ReturnType<
  typeof useBlockUserMutation
>;
export type BlockUserMutationResult = Apollo.MutationResult<Types.BlockUserMutation>;
export type BlockUserMutationOptions = Apollo.BaseMutationOptions<
  Types.BlockUserMutation,
  Types.BlockUserMutationVariables
>;
export const UnblockUserDocument = gql`
  mutation unblockUser($input: UnblockUserInput!) {
    unblockUser(input: $input) {
      user {
        id
        isBlocked
      }
    }
  }
`;
export type UnblockUserMutationFn = Apollo.MutationFunction<
  Types.UnblockUserMutation,
  Types.UnblockUserMutationVariables
>;

/**
 * __useUnblockUserMutation__
 *
 * To run a mutation, you first call `useUnblockUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnblockUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unblockUserMutation, { data, loading, error }] = useUnblockUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnblockUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UnblockUserMutation,
    Types.UnblockUserMutationVariables
  >
) {
  return Apollo.useMutation<
    Types.UnblockUserMutation,
    Types.UnblockUserMutationVariables
  >(UnblockUserDocument, baseOptions);
}
export type UnblockUserMutationHookResult = ReturnType<
  typeof useUnblockUserMutation
>;
export type UnblockUserMutationResult = Apollo.MutationResult<Types.UnblockUserMutation>;
export type UnblockUserMutationOptions = Apollo.BaseMutationOptions<
  Types.UnblockUserMutation,
  Types.UnblockUserMutationVariables
>;
export const CreateProfileDocument = gql`
  mutation CreateProfile($input: CreateUserInput!) {
    createUser(input: $input) {
      user {
        id
        gender
        createdAt
        age
        description
      }
    }
  }
`;
export type CreateProfileMutationFn = Apollo.MutationFunction<
  Types.CreateProfileMutation,
  Types.CreateProfileMutationVariables
>;

/**
 * __useCreateProfileMutation__
 *
 * To run a mutation, you first call `useCreateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProfileMutation, { data, loading, error }] = useCreateProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateProfileMutation,
    Types.CreateProfileMutationVariables
  >
) {
  return Apollo.useMutation<
    Types.CreateProfileMutation,
    Types.CreateProfileMutationVariables
  >(CreateProfileDocument, baseOptions);
}
export type CreateProfileMutationHookResult = ReturnType<
  typeof useCreateProfileMutation
>;
export type CreateProfileMutationResult = Apollo.MutationResult<Types.CreateProfileMutation>;
export type CreateProfileMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateProfileMutation,
  Types.CreateProfileMutationVariables
>;
export const GetUserLocationDocument = gql`
  query GetUserLocation($username: ID!) {
    user(id: $username) {
      id
      location {
        coordinates {
          latitude
          longitude
        }
      }
    }
  }
`;

/**
 * __useGetUserLocationQuery__
 *
 * To run a query within a React component, call `useGetUserLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserLocationQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useGetUserLocationQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetUserLocationQuery,
    Types.GetUserLocationQueryVariables
  >
) {
  return Apollo.useQuery<
    Types.GetUserLocationQuery,
    Types.GetUserLocationQueryVariables
  >(GetUserLocationDocument, baseOptions);
}
export function useGetUserLocationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetUserLocationQuery,
    Types.GetUserLocationQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    Types.GetUserLocationQuery,
    Types.GetUserLocationQueryVariables
  >(GetUserLocationDocument, baseOptions);
}
export type GetUserLocationQueryHookResult = ReturnType<
  typeof useGetUserLocationQuery
>;
export type GetUserLocationLazyQueryHookResult = ReturnType<
  typeof useGetUserLocationLazyQuery
>;
export type GetUserLocationQueryResult = Apollo.QueryResult<
  Types.GetUserLocationQuery,
  Types.GetUserLocationQueryVariables
>;
export const SetLocationDocument = gql`
  mutation SetLocation($input: SetLocationInput!) {
    setLocation(input: $input) {
      location {
        coordinates {
          latitude
          longitude
        }
      }
    }
  }
`;
export type SetLocationMutationFn = Apollo.MutationFunction<
  Types.SetLocationMutation,
  Types.SetLocationMutationVariables
>;

/**
 * __useSetLocationMutation__
 *
 * To run a mutation, you first call `useSetLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setLocationMutation, { data, loading, error }] = useSetLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.SetLocationMutation,
    Types.SetLocationMutationVariables
  >
) {
  return Apollo.useMutation<
    Types.SetLocationMutation,
    Types.SetLocationMutationVariables
  >(SetLocationDocument, baseOptions);
}
export type SetLocationMutationHookResult = ReturnType<
  typeof useSetLocationMutation
>;
export type SetLocationMutationResult = Apollo.MutationResult<Types.SetLocationMutation>;
export type SetLocationMutationOptions = Apollo.BaseMutationOptions<
  Types.SetLocationMutation,
  Types.SetLocationMutationVariables
>;
export const CreateMessageDocument = gql`
  mutation CreateMessage($input: CreateMessageInput!) {
    createMessage(input: $input) {
      message {
        ...MessagesScreen_Message
      }
    }
  }
  ${MessagesScreen_MessageFragmentDoc}
`;
export type CreateMessageMutationFn = Apollo.MutationFunction<
  Types.CreateMessageMutation,
  Types.CreateMessageMutationVariables
>;

/**
 * __useCreateMessageMutation__
 *
 * To run a mutation, you first call `useCreateMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMessageMutation, { data, loading, error }] = useCreateMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateMessageMutation,
    Types.CreateMessageMutationVariables
  >
) {
  return Apollo.useMutation<
    Types.CreateMessageMutation,
    Types.CreateMessageMutationVariables
  >(CreateMessageDocument, baseOptions);
}
export type CreateMessageMutationHookResult = ReturnType<
  typeof useCreateMessageMutation
>;
export type CreateMessageMutationResult = Apollo.MutationResult<Types.CreateMessageMutation>;
export type CreateMessageMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateMessageMutation,
  Types.CreateMessageMutationVariables
>;
export const EditUserDocument = gql`
  mutation EditUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        ...UserCard_User
      }
    }
  }
  ${UserCard_UserFragmentDoc}
`;
export type EditUserMutationFn = Apollo.MutationFunction<
  Types.EditUserMutation,
  Types.EditUserMutationVariables
>;

/**
 * __useEditUserMutation__
 *
 * To run a mutation, you first call `useEditUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserMutation, { data, loading, error }] = useEditUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.EditUserMutation,
    Types.EditUserMutationVariables
  >
) {
  return Apollo.useMutation<
    Types.EditUserMutation,
    Types.EditUserMutationVariables
  >(EditUserDocument, baseOptions);
}
export type EditUserMutationHookResult = ReturnType<typeof useEditUserMutation>;
export type EditUserMutationResult = Apollo.MutationResult<Types.EditUserMutation>;
export type EditUserMutationOptions = Apollo.BaseMutationOptions<
  Types.EditUserMutation,
  Types.EditUserMutationVariables
>;
export const ConversationsDocument = gql`
  query Conversations($input: ConversationsQueryInput!) {
    conversations(input: $input) {
      edges {
        node {
          ...ConversationsScreen_Conversation
        }
      }
      pageInfo {
        hasNextPage
        nextToken
      }
    }
  }
  ${ConversationsScreen_ConversationFragmentDoc}
`;

/**
 * __useConversationsQuery__
 *
 * To run a query within a React component, call `useConversationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConversationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConversationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.ConversationsQuery,
    Types.ConversationsQueryVariables
  >
) {
  return Apollo.useQuery<
    Types.ConversationsQuery,
    Types.ConversationsQueryVariables
  >(ConversationsDocument, baseOptions);
}
export function useConversationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ConversationsQuery,
    Types.ConversationsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    Types.ConversationsQuery,
    Types.ConversationsQueryVariables
  >(ConversationsDocument, baseOptions);
}
export type ConversationsQueryHookResult = ReturnType<
  typeof useConversationsQuery
>;
export type ConversationsLazyQueryHookResult = ReturnType<
  typeof useConversationsLazyQuery
>;
export type ConversationsQueryResult = Apollo.QueryResult<
  Types.ConversationsQuery,
  Types.ConversationsQueryVariables
>;
export const GetCurrentUserDocument = gql`
  query GetCurrentUser($username: ID!) {
    user(id: $username) {
      ...UserCard_User
    }
  }
  ${UserCard_UserFragmentDoc}
`;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useGetCurrentUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetCurrentUserQuery,
    Types.GetCurrentUserQueryVariables
  >
) {
  return Apollo.useQuery<
    Types.GetCurrentUserQuery,
    Types.GetCurrentUserQueryVariables
  >(GetCurrentUserDocument, baseOptions);
}
export function useGetCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetCurrentUserQuery,
    Types.GetCurrentUserQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    Types.GetCurrentUserQuery,
    Types.GetCurrentUserQueryVariables
  >(GetCurrentUserDocument, baseOptions);
}
export type GetCurrentUserQueryHookResult = ReturnType<
  typeof useGetCurrentUserQuery
>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<
  typeof useGetCurrentUserLazyQuery
>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<
  Types.GetCurrentUserQuery,
  Types.GetCurrentUserQueryVariables
>;
export const UsersInRadiusDocument = gql`
  query UsersInRadius($input: SearchInput!) {
    usersInRadius(input: $input) {
      edges {
        node {
          ...UserCard_User
        }
      }
      pageInfo {
        hasNextPage
        nextToken
      }
    }
  }
  ${UserCard_UserFragmentDoc}
`;

/**
 * __useUsersInRadiusQuery__
 *
 * To run a query within a React component, call `useUsersInRadiusQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersInRadiusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersInRadiusQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUsersInRadiusQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.UsersInRadiusQuery,
    Types.UsersInRadiusQueryVariables
  >
) {
  return Apollo.useQuery<
    Types.UsersInRadiusQuery,
    Types.UsersInRadiusQueryVariables
  >(UsersInRadiusDocument, baseOptions);
}
export function useUsersInRadiusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.UsersInRadiusQuery,
    Types.UsersInRadiusQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    Types.UsersInRadiusQuery,
    Types.UsersInRadiusQueryVariables
  >(UsersInRadiusDocument, baseOptions);
}
export type UsersInRadiusQueryHookResult = ReturnType<
  typeof useUsersInRadiusQuery
>;
export type UsersInRadiusLazyQueryHookResult = ReturnType<
  typeof useUsersInRadiusLazyQuery
>;
export type UsersInRadiusQueryResult = Apollo.QueryResult<
  Types.UsersInRadiusQuery,
  Types.UsersInRadiusQueryVariables
>;
export const MessagesDocument = gql`
  query Messages($input: MessagesQueryInput!) {
    messages(input: $input) {
      edges {
        node {
          ...MessagesScreen_Message
        }
      }
      pageInfo {
        hasNextPage
        nextToken
      }
    }
  }
  ${MessagesScreen_MessageFragmentDoc}
`;

/**
 * __useMessagesQuery__
 *
 * To run a query within a React component, call `useMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessagesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMessagesQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.MessagesQuery,
    Types.MessagesQueryVariables
  >
) {
  return Apollo.useQuery<Types.MessagesQuery, Types.MessagesQueryVariables>(
    MessagesDocument,
    baseOptions
  );
}
export function useMessagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.MessagesQuery,
    Types.MessagesQueryVariables
  >
) {
  return Apollo.useLazyQuery<Types.MessagesQuery, Types.MessagesQueryVariables>(
    MessagesDocument,
    baseOptions
  );
}
export type MessagesQueryHookResult = ReturnType<typeof useMessagesQuery>;
export type MessagesLazyQueryHookResult = ReturnType<
  typeof useMessagesLazyQuery
>;
export type MessagesQueryResult = Apollo.QueryResult<
  Types.MessagesQuery,
  Types.MessagesQueryVariables
>;
export const GetUserDocument = gql`
  query GetUser($username: ID!) {
    user(id: $username) {
      ...UserCard_User
      isBlocked
    }
  }
  ${UserCard_UserFragmentDoc}
`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useGetUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetUserQuery,
    Types.GetUserQueryVariables
  >
) {
  return Apollo.useQuery<Types.GetUserQuery, Types.GetUserQueryVariables>(
    GetUserDocument,
    baseOptions
  );
}
export function useGetUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetUserQuery,
    Types.GetUserQueryVariables
  >
) {
  return Apollo.useLazyQuery<Types.GetUserQuery, Types.GetUserQueryVariables>(
    GetUserDocument,
    baseOptions
  );
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<
  Types.GetUserQuery,
  Types.GetUserQueryVariables
>;
