import { Box, makeStyles, Typography } from "@material-ui/core";
import { FunctionComponent, useEffect, useRef } from "react";
import { MessagesScreen_MessageFragment } from "queries/autogenerate/operations";
import useDefaults from "styles/defaults";
import LoadingScreen from "components/LoadingScreen";
import MessageItem from "components/MessageItem";

const useStyles = makeStyles((theme) => ({
  messages: {
    display: "flex",
    minHeight: "105%",
    backgroundColor: "inherit",
    flexDirection: "column-reverse",
    padding: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(8),
  },
}));

export interface Props {
  messages: MessagesScreen_MessageFragment[];
  username: string;
  hasNextPage: boolean;
  loading: boolean;
  fetchMore: () => void;
}

const MessageList: FunctionComponent<Props> = ({
  messages,
  username,
  hasNextPage,
  fetchMore,
  loading,
}) => {
  const defaultClasses = useDefaults();
  const classes = useStyles();

  const scrollEndRef = useRef<null | HTMLDivElement>(null);

  const scrollToBottom = () => {
    scrollEndRef.current?.scrollIntoView();
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    const handleScroll = () => {
      if (!hasNextPage) {
        return;
      }
      if (
        window.innerHeight + document.documentElement.scrollTop !==
        document.documentElement.offsetHeight
      )
        return;
      fetchMore();
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [fetchMore, hasNextPage]);

  if (!messages.length) {
    return (
      <Typography
        className={defaultClasses.emptyText}
        align="center"
        variant="body1"
      >
        No messages. Say Hi!
      </Typography>
    );
  }
  return (
    <Box
      className={classes.messages}
      style={{ ...(!hasNextPage && { minHeight: "100%" }) }}
    >
      <div ref={scrollEndRef} />
      {messages.map((message) => (
        <MessageItem
          align={message.user.id === username ? "right" : "left"}
          textBoxStyle={message.user.id === username ? "dark" : "light"}
          key={message.id}
          message={message}
          profileLink={message.user.id !== username}
        />
      ))}
      {loading && (
        <LoadingScreen
          style={{ position: "absolute", left: 0, right: 0, top: "5%" }}
        />
      )}
    </Box>
  );
};

export default MessageList;
