import {
  Divider,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { AvatarGroup } from "@material-ui/lab";
import UserAvatar from "components/UserAvatar";
import { ConversationsScreen_ConversationFragment } from "queries/autogenerate/operations";
import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    background: theme.palette.common.white,
  },
  description: {
    margin: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
  avatar: {
    "&:not(:first-child)": {
      marginLeft: theme.spacing(-2),
    },
  },
}));

export interface Props {
  conversation: ConversationsScreen_ConversationFragment;
  username: string;
  index: number;
}

const ConversationItem: FunctionComponent<Props> = ({
  conversation,
  username,
  index,
}) => {
  const otherUsers = conversation.users.filter(({ id }) => id !== username);

  const classes = useStyles();
  return (
    <>
      {index === 0 && <Divider />}
      <ListItem
        component={Link}
        to={`messages/${otherUsers.map(({ id }) => id).join(" ")}`}
        className={classes.root}
      >
        <AvatarGroup>
          {otherUsers.map((user) => (
            <UserAvatar
              className={classes.avatar}
              key={user.id}
              size="medium"
              user={user}
            />
          ))}
        </AvatarGroup>
        <ListItemText
          className={classes.description}
          primary={otherUsers.map(({ id }) => id).join(", ")}
          secondary={
            <>
              <Typography component="span" variant="body2" color="textPrimary">
                {conversation.latestMessage.user.id}
              </Typography>
              {conversation.latestMessage.text &&
                ` — ${conversation.latestMessage.text} (${moment(
                  conversation.latestMessage.createdAt
                ).fromNow()})`}
            </>
          }
        />
      </ListItem>
      <Divider />
    </>
  );
};

export default ConversationItem;
