import React from "react";
import AuthState from "./components/AuthState";
import Apollo from "./components/Apollo";
import Router from "./Router";
import MenuBar from "./components/MenuBar";
import Footer from "components/Footer";

const App: React.FunctionComponent = () => (
  <AuthState>
    <Apollo>
      <Router header={<MenuBar />} footer={<Footer />} />
    </Apollo>
  </AuthState>
);

export default App;
