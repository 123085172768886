import { Box, makeStyles, Typography } from "@material-ui/core";
import { FunctionComponent } from "react";
import { MessagesScreen_MessageFragment } from "queries/autogenerate/operations";
import UserAvatar from "components/UserAvatar";
import moment from "moment";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  textBox: {
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.75),
    paddingLeft: theme.spacing(1.25),
    paddingRight: theme.spacing(1.25),
    borderRadius: theme.shape.borderRadius * 3,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    display: "inline-block",
  },
  messageRoot: {
    display: "flex",
    alignItems: "flex-start",
    marginBottom: theme.spacing(1),
  },
  sentTime: {
    paddingTop: theme.spacing(0.25),
    paddingLeft: theme.spacing(1.25),
    paddingRight: theme.spacing(1.25),
  },
  right: {
    flexDirection: "row-reverse",
  },
  left: {
    flexDirection: "row",
  },
  light: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
  },
  dark: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  textBoxContainer: {
    display: "flex",
  },
  rightTextBox: {
    marginLeft: "auto",
  },
  leftTextBox: {
    marginRight: "auto",
  },
}));

export interface Props {
  message: MessagesScreen_MessageFragment;
  align: "left" | "right";
  textBoxStyle: "light" | "dark";
  profileLink: boolean;
}

const MessageItem: FunctionComponent<Props> = ({
  message,
  align,
  textBoxStyle,
  profileLink,
}) => {
  const classes = useStyles();

  return (
    <Box className={`${classes.messageRoot} ${classes[align]}`}>
      {profileLink ? (
        <Link
          style={{ textDecoration: "none" }}
          to={`/users/${message.user.id}`}
        >
          <UserAvatar size="small" user={message.user} />
        </Link>
      ) : (
        <UserAvatar size="small" user={message.user} />
      )}
      <Box>
        <Box className={classes.textBoxContainer}>
          <Box
            className={`${classes.textBox} ${classes[textBoxStyle]} ${
              align === "right"
                ? classes["rightTextBox"]
                : classes["leftTextBox"]
            }`}
          >
            <Typography variant="body1">{message.text}</Typography>
          </Box>
        </Box>
        <Box className={classes.sentTime}>
          {/* Add 10 seconds to the time, sometimes server time can be a bit off */}
          <Typography display="block" align={align} variant="caption">
            {moment(message.createdAt).subtract("10", "seconds").fromNow()}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default MessageItem;
