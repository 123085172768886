import { Container, makeStyles } from "@material-ui/core";
import { FunctionComponent, UIEvent } from "react";
import ConversationList from "components/ConversationList";
import { useConversationsQuery } from "queries/autogenerate/hooks";
import LoadingScreen from "components/LoadingScreen";
import useCurrentUsername from "hooks/useCurrentUsername";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    height: "100%",
    overflowY: "scroll",
  },
  title: {
    padding: theme.spacing(2),
  },
  view: {
    padding: 0,
    flexGrow: 1,
    backgroundColor: "#dcdcdc",
  },
}));

export const PAGE_SIZE = 12;

const Conversations: FunctionComponent = () => {
  const classes = useStyles();
  const username = useCurrentUsername();
  const { data, loading, fetchMore } = useConversationsQuery({
    variables: {
      input: {
        options: {
          startToken: null,
          limit: PAGE_SIZE,
        },
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  const handleScroll = (event: UIEvent<HTMLDivElement, globalThis.UIEvent>) => {
    const target = event.target as HTMLDivElement;
    if (target.scrollHeight - target.scrollTop === target.clientHeight) {
      fetchMore({
        variables: {
          input: {
            options: {
              limit: PAGE_SIZE,
              startToken: data?.conversations.pageInfo.nextToken,
            },
          },
        },
      });
    }
  };

  const conversations = data?.conversations.edges.map(({ node }) => node);
  const hasNextPage = !!data?.conversations.pageInfo.hasNextPage;

  return (
    <Container component="div" className={classes.root} onScroll={handleScroll}>
      {conversations && username ? (
        <ConversationList
          conversations={conversations}
          username={username}
          loading={loading}
          hasNextPage={hasNextPage}
        />
      ) : (
        <LoadingScreen />
      )}
    </Container>
  );
};

export default Conversations;
