import { Avatar, makeStyles } from "@material-ui/core";
import { FunctionComponent } from "react";
import { Gender, User } from "queries/autogenerate/schemas";

const useStyles = makeStyles((theme) => ({
  male: {
    backgroundColor: "#b53f49",
  },
  female: {
    backgroundColor: "#ab3fb5",
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    fontSize: theme.spacing(4),
  },
  medium: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    fontSize: theme.spacing(3),
  },
  small: {},
}));

export interface Props {
  user: Pick<User, "id" | "gender">;
  size: "large" | "medium" | "small";
  className?: string;
}

const UserAvatar: FunctionComponent<Props> = ({ user, size, className }) => {
  const classes = useStyles();
  return (
    <Avatar
      className={`${className} ${classes[size]} ${
        user.gender === Gender.Male ? classes.male : classes.female
      }`}
    >
      {user.id.charAt(0).toUpperCase()}
    </Avatar>
  );
};

export default UserAvatar;
