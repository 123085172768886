import { FunctionComponent, useState } from "react";
import Card from "@material-ui/core/Card";
import { Gender } from "queries/autogenerate/schemas";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import { TextField, Typography } from "@material-ui/core";
import {
  GetUserQuery,
  GetUserQueryVariables,
  UserCard_UserFragment,
} from "queries/autogenerate/operations";
import UserAvatar from "components/UserAvatar";
import { Check, Edit } from "@material-ui/icons";
import {
  GetUserDocument,
  useEditUserMutation,
} from "queries/autogenerate/hooks";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    marginBottom: theme.spacing(2),
  },
  text: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  editButton: {
    marginLeft: "auto",
    alignSelf: "flex-start",
    padding: 0,
  },
}));

export interface Props {
  edit?: Boolean;
  user: UserCard_UserFragment;
}

const UserCard: FunctionComponent<Props> = ({ user, edit }) => {
  const classes = useStyles();
  const [editUserMutation] = useEditUserMutation();

  const genderDisplay = (gender: Gender) => {
    return gender === Gender.Male ? "Male" : "Female";
  };

  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [description, setDescription] = useState<string>(
    user.description || ""
  );

  const saveChanges = async () => {
    if (description !== user.description) {
      await editUserMutation({
        variables: {
          input: {
            user: {
              description: description ? description : null,
            },
          },
        },
        update: (proxy, { data }) => {
          if (!data?.updateUser?.user) {
            return;
          }
          proxy.writeQuery<GetUserQuery, GetUserQueryVariables>({
            query: GetUserDocument,
            variables: {
              username: user.id,
            },
            data: {
              user: {
                ...data?.updateUser.user,
                isBlocked: false,
              },
            },
          });
        },
      });
    }

    setIsEditMode(false);
  };

  return (
    <Card className={classes.root}>
      <UserAvatar size="large" user={user} />
      <div className={classes.text}>
        <Typography variant="h5">{user.id}</Typography>
        <Typography variant="body1">
          {genderDisplay(user.gender)} {user.age}
        </Typography>
        {isEditMode ? (
          <TextField
            multiline
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        ) : (
          user.description && (
            <Typography variant="body2">{user.description}</Typography>
          )
        )}
      </div>
      {edit &&
        (isEditMode ? (
          <IconButton className={classes.editButton} onClick={saveChanges}>
            <Check />
          </IconButton>
        ) : (
          <IconButton
            className={classes.editButton}
            onClick={() => setIsEditMode(true)}
          >
            <Edit />
          </IconButton>
        ))}
    </Card>
  );
};

export default UserCard;
