import {
  Button,
  Card,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { LocationCoordinates } from "queries/autogenerate/schemas";
import { FunctionComponent, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  GetUserLocationDocument,
  useGetUserLocationQuery,
  useSetLocationMutation,
} from "queries/autogenerate/hooks";
import {
  GetUserLocationQuery,
  GetUserLocationQueryVariables,
} from "queries/autogenerate/operations";
import { MutationUpdaterFn } from "@apollo/client";
import cities from "data/cities.json";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  clearButton: {
    marginLeft: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  btnContainer: {
    marginTop: theme.spacing(1.5),
  },
  locationTypeForm: {
    marginTop: theme.spacing(1.5),
  },
}));

type LocationType = "device" | "city";

export interface Props {
  username: string;
}

const LocationCard: FunctionComponent<Props> = ({ username }) => {
  const [locationType, setLocationType] = useState<LocationType>("device");
  const [selectedCity, setSelectedCity] = useState<LocationCoordinates | null>(
    null
  );

  const classes = useStyles();
  const { data } = useGetUserLocationQuery({
    variables: {
      username,
    },
  });

  const [setLocationMutation] = useSetLocationMutation();

  const userLocation = data?.user?.location;

  const updateCache: MutationUpdaterFn = (proxy, { data }) => {
    const userCache = proxy.readQuery<
      GetUserLocationQuery,
      GetUserLocationQueryVariables
    >({
      query: GetUserLocationDocument,
      variables: {
        username,
      },
    });

    if (userCache?.user) {
      proxy.writeQuery<GetUserLocationQuery, GetUserLocationQueryVariables>({
        query: GetUserLocationDocument,
        variables: {
          username,
        },
        data: {
          user: {
            ...userCache.user,
            location: data?.setLocation.location,
          },
        },
      });
    }
  };

  const setLocation = async (
    { latitude, longitude }: LocationCoordinates,
    fuzzCoordinates: boolean
  ) => {
    await setLocationMutation({
      variables: {
        input: {
          location: {
            coordinates: {
              latitude: fuzzCoordinates
                ? parseFloat(latitude.toFixed(3))
                : latitude,
              longitude: fuzzCoordinates
                ? parseFloat(longitude.toFixed(3))
                : longitude,
            },
          },
        },
      },
      update: updateCache,
    });
  };

  const getUserLocation = async () => {
    navigator.geolocation.getCurrentPosition(async (position) => {
      await setLocation(position.coords, true);
    });
  };

  const clearUserLocation = async () => {
    await setLocationMutation({
      variables: {
        input: {
          location: null,
        },
      },
      update: updateCache,
    });
  };

  const setLocationFromCity = (e: any) => {
    const { latitude, longitude } = cities[e.dataset.optionIndex];
    setSelectedCity({ latitude, longitude });
  };

  return (
    <Card className={classes.root}>
      <Typography className={classes.title} variant="h5">
        My Location
      </Typography>
      {userLocation && (
        <>
          <Typography variant="body1">
            Latitude: {userLocation.coordinates.latitude}
          </Typography>
          <Typography variant="body1">
            Longitude: {userLocation.coordinates.longitude}
          </Typography>
        </>
      )}
      <FormControl component="fieldset" className={classes.locationTypeForm}>
        <FormLabel component="legend">Location Type</FormLabel>
        <RadioGroup
          aria-label="location type"
          name="locationType"
          value={locationType}
          onChange={(e) => setLocationType(e.target.value as LocationType)}
        >
          <FormControlLabel
            value="device"
            control={<Radio />}
            label="My Device"
          />
          <FormControlLabel value="city" control={<Radio />} label="City" />
        </RadioGroup>
      </FormControl>
      {locationType === "city" && (
        <Autocomplete
          onChange={(e) => setLocationFromCity(e.target)}
          options={cities}
          getOptionLabel={(option) => option.city + ", " + option.state}
          renderInput={(params) => <TextField {...params} label="Cities" />}
        />
      )}
      <div className={classes.btnContainer}>
        <Button
          onClick={
            locationType === "city"
              ? () => {
                  if (!selectedCity) return;
                  setLocation(selectedCity, false);
                }
              : getUserLocation
          }
          variant="contained"
          color="primary"
        >
          Set Location
        </Button>
        <Button onClick={clearUserLocation} className={classes.clearButton}>
          Clear Location
        </Button>
      </div>
    </Card>
  );
};

export default LocationCard;
