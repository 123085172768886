import { List, makeStyles, Typography } from "@material-ui/core";
import { FunctionComponent } from "react";
import useDefaults from "styles/defaults";
import { ConversationsScreen_ConversationFragment } from "queries/autogenerate/operations";
import ConversationItem from "components/ConversationItem";
import LoadingScreen from "components/LoadingScreen";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 0,
    minHeight: "105%",
  },
}));

export interface Props {
  conversations: ConversationsScreen_ConversationFragment[];
  username: string;
  hasNextPage: boolean;
  loading: boolean;
}

const ConversationList: FunctionComponent<Props> = ({
  conversations,
  username,
  hasNextPage,
  loading,
}) => {
  const defaultClasses = useDefaults();
  const classes = useStyles();

  if (!conversations.length) {
    return (
      <Typography
        className={defaultClasses.emptyText}
        align="center"
        variant="body1"
      >
        You have no conversations.
      </Typography>
    );
  }

  return (
    <List
      component="div"
      className={classes.root}
      style={{ ...(!hasNextPage && { minHeight: "100%" }) }}
    >
      {conversations.map((convo, index) => (
        <ConversationItem
          key={convo.id}
          index={index}
          username={username}
          conversation={convo}
        />
      ))}
      {loading && <LoadingScreen />}
    </List>
  );
};

export default ConversationList;
