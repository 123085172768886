export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
};

export type CreateUserInput = {
  user: NewUser;
};

export type CreateUserPayload = {
  __typename?: "CreateUserPayload";
  user: User;
};

export enum Gender {
  Male = "MALE",
  Female = "FEMALE",
}

export type NewUser = {
  description?: Maybe<Scalars["String"]>;
  birthday: Scalars["Date"];
  gender: Gender;
};

export type UpdateUser = {
  description?: Maybe<Scalars["String"]>;
};

export type UpdateUserInput = {
  user: UpdateUser;
};

export type UpdateUserPayload = {
  __typename?: "UpdateUserPayload";
  user: User;
};

export type User = {
  __typename?: "User";
  id: Scalars["ID"];
  createdAt: Scalars["DateTime"];
  description?: Maybe<Scalars["String"]>;
  age: Scalars["Int"];
  gender: Gender;
  isBlocked: Scalars["Boolean"];
  location?: Maybe<Location>;
};

export type BlockUserInput = {
  userId: Scalars["ID"];
};

export type BlockUserPayload = {
  __typename?: "BlockUserPayload";
  user?: Maybe<User>;
};

export type Conversation = {
  __typename?: "Conversation";
  id: Scalars["ID"];
  users: Array<User>;
  latestMessage: Message;
};

export type ConversationConnection = {
  __typename?: "ConversationConnection";
  edges: Array<ConversationConnectionEdge>;
  pageInfo: PageInfo;
};

export type ConversationConnectionEdge = {
  __typename?: "ConversationConnectionEdge";
  node: Conversation;
};

export type ConversationsQueryInput = {
  options: QueryOptions;
};

export type CreateMessageInput = {
  userIds: Array<Scalars["ID"]>;
  message: NewMessage;
};

export type CreateMessagePayload = {
  __typename?: "CreateMessagePayload";
  message: Message;
};

export type Message = {
  __typename?: "Message";
  id: Scalars["ID"];
  user: User;
  createdAt: Scalars["DateTime"];
  text?: Maybe<Scalars["String"]>;
};

export type MessageConnection = {
  __typename?: "MessageConnection";
  edges: Array<MessageConnectionEdge>;
  pageInfo: PageInfo;
};

export type MessageConnectionEdge = {
  __typename?: "MessageConnectionEdge";
  node: Message;
};

export type MessagesQueryInput = {
  userIds: Array<Scalars["ID"]>;
  options: QueryOptions;
};

export type NewMessage = {
  text?: Maybe<Scalars["String"]>;
};

export type PageInfo = {
  __typename?: "PageInfo";
  hasNextPage: Scalars["Boolean"];
  nextToken?: Maybe<Scalars["String"]>;
};

export type QueryOptions = {
  limit?: Maybe<Scalars["Int"]>;
  startToken?: Maybe<Scalars["String"]>;
};

export type UnblockUserInput = {
  userId: Scalars["ID"];
};

export type UnblockUserPayload = {
  __typename?: "UnblockUserPayload";
  user?: Maybe<User>;
};

export enum DistanceUnit {
  Miles = "MILES",
  Kilometers = "KILOMETERS",
}

export type Location = {
  __typename?: "Location";
  coordinates: LocationCoordinates;
};

export type LocationCoordinates = {
  __typename?: "LocationCoordinates";
  latitude: Scalars["Float"];
  longitude: Scalars["Float"];
};

export type LocationInput = {
  coordinates: LocationInputCoordinates;
};

export type LocationInputCoordinates = {
  latitude: Scalars["Float"];
  longitude: Scalars["Float"];
};

export type Radius = {
  unit: DistanceUnit;
  value: Scalars["Float"];
};

export type SearchInput = {
  coordinates: LocationInputCoordinates;
  radius: Radius;
  options: QueryOptions;
};

export type SetLocationInput = {
  location?: Maybe<LocationInput>;
};

export type SetLocationPayload = {
  __typename?: "SetLocationPayload";
  location?: Maybe<Location>;
};

export type UserConnection = {
  __typename?: "UserConnection";
  edges: Array<UserNode>;
  pageInfo: PageInfo;
};

export type UserNode = {
  __typename?: "UserNode";
  node: User;
};

export type Query = {
  __typename?: "Query";
  user?: Maybe<User>;
  conversations: ConversationConnection;
  messages: MessageConnection;
  usersInRadius: UserConnection;
};

export type QueryUserArgs = {
  id: Scalars["ID"];
};

export type QueryConversationsArgs = {
  input: ConversationsQueryInput;
};

export type QueryMessagesArgs = {
  input: MessagesQueryInput;
};

export type QueryUsersInRadiusArgs = {
  input: SearchInput;
};

export type Mutation = {
  __typename?: "Mutation";
  createUser: CreateUserPayload;
  updateUser: UpdateUserPayload;
  createMessage?: Maybe<CreateMessagePayload>;
  blockUser?: Maybe<BlockUserPayload>;
  unblockUser?: Maybe<UnblockUserPayload>;
  setLocation: SetLocationPayload;
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type MutationCreateMessageArgs = {
  input: CreateMessageInput;
};

export type MutationBlockUserArgs = {
  input: BlockUserInput;
};

export type MutationUnblockUserArgs = {
  input: UnblockUserInput;
};

export type MutationSetLocationArgs = {
  input: SetLocationInput;
};
