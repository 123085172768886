import { Box, Container, makeStyles } from "@material-ui/core";
import { FunctionComponent, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import LoadingScreen from "components/LoadingScreen";
import MessageInput from "components/MessageInput";
import MessageList from "components/MessageList";
import useCurrentUsername from "hooks/useCurrentUsername";
import { useMessagesQuery } from "queries/autogenerate/hooks";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    margin: 0,
    paddingBottom: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  view: {
    padding: 0,
    flexGrow: 1,
    backgroundColor: "#dcdcdc",
  },
}));

export interface NavigationParams {
  users: string;
}

export const PAGE_SIZE = 12;

const Messages: FunctionComponent = () => {
  const classes = useStyles();

  const { users } = useParams<NavigationParams>();
  const username = useCurrentUsername();
  const { push } = useHistory();
  const usersArray = users.split(" ");

  const isValidUsers = !!usersArray.length;

  useEffect(() => {
    if (!isValidUsers) {
      push("/messages");
    }
  }, [users, push, isValidUsers]);

  const { data, loading, fetchMore } = useMessagesQuery({
    variables: {
      input: {
        userIds: usersArray,
        options: {
          limit: PAGE_SIZE,
          startToken: null,
        },
      },
    },
    pollInterval: 10 * 1000,
    notifyOnNetworkStatusChange: true,
  });

  const fetchMoreMessages = () => {
    fetchMore({
      variables: {
        input: {
          userIds: usersArray,
          options: {
            limit: PAGE_SIZE,
            startToken: data?.messages.pageInfo.nextToken,
          },
        },
      },
    });
  };

  const messages = data?.messages.edges.map(({ node }) => node);
  const hasNextPage = !!data?.messages.pageInfo.hasNextPage;

  if (!isValidUsers) {
    return null;
  }

  return (
    <Container className={classes.root}>
      <Box className={classes.view}>
        {username && messages ? (
          <MessageList
            username={username}
            messages={messages}
            hasNextPage={hasNextPage}
            fetchMore={fetchMoreMessages}
            loading={loading}
          />
        ) : (
          <LoadingScreen />
        )}
      </Box>
      <MessageInput userIds={usersArray} />
    </Container>
  );
};

export default Messages;
