import { CircularProgress, Container, makeStyles } from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { FunctionComponent } from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
  },
}));

export interface Props {
  style?: CSSProperties | undefined;
}

const LoadingScreen: FunctionComponent<Props> = ({ style }) => {
  const classes = useStyles();

  return (
    <Container style={style} className={classes.container}>
      <CircularProgress />
    </Container>
  );
};

export default LoadingScreen;
