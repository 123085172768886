import { useState, FunctionComponent, useEffect } from "react";

import Amplify from "aws-amplify";
import {
  AmplifyAuthenticator,
  AmplifySignIn,
  AmplifySignUp,
} from "@aws-amplify/ui-react";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
Amplify.configure({
  userPoolId: process.env.REACT_APP_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_WEB_CLIENT_ID,
  region: process.env.REACT_APP_REGION,
});

const AuthStateApp: FunctionComponent = ({ children }) => {
  const [authState, setAuthState] = useState<AuthState>();

  useEffect(() => {
    onAuthUIStateChange((nextAuthState) => {
      setAuthState(nextAuthState);
    });
  }, []);

  return authState === AuthState.SignedIn ? (
    <div className="App">{children}</div>
  ) : (
    <AmplifyAuthenticator>
      <AmplifySignUp
        slot="sign-up"
        headerText="Sign Up"
        formFields={[
          { label: "Username*", type: "username", required: true },
          { label: "Password*", type: "password", required: true },
          {
            label: "Email Address",
            type: "email",
            required: false,
            value: " ",
          },
          { label: "Phone Number", type: "phone_number", required: false },
        ]}
      />
      <AmplifySignIn slot="sign-in" headerText="Sign In" />
    </AmplifyAuthenticator>
  );
};

export default AuthStateApp;
