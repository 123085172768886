import { IconButton, InputBase, makeStyles } from "@material-ui/core";
import { FormEvent, FunctionComponent, useState } from "react";
import { AppBar } from "@material-ui/core";
import { Send } from "@material-ui/icons";
import {
  MessagesDocument,
  useCreateMessageMutation,
} from "queries/autogenerate/hooks";
import {
  MessagesQuery,
  MessagesQueryVariables,
} from "queries/autogenerate/operations";
import { PAGE_SIZE } from "screens/Messages/Messages";

const useStyles = makeStyles((theme) => ({
  appBar: {
    top: "auto",
    bottom: 0,
    zIndex: 1,
  },
  inputRow: {
    display: "flex",
    paddingTop: theme.spacing(1.25),
    paddingBottom: theme.spacing(1.25),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    alignContent: "center",
  },
  sendIcon: {
    color: theme.palette.common.white,
  },
  sendButton: {
    padding: theme.spacing(1),
  },
  textField: {
    background: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius * 2,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

export interface Props {
  userIds: string[];
}

const MessageInput: FunctionComponent<Props> = ({ userIds }) => {
  const classes = useStyles();
  const [text, setText] = useState<string>("");
  const [createMessage] = useCreateMessageMutation();
  const [disabled, setDisabled] = useState<boolean>(false);

  const sendMessage = async (e: FormEvent) => {
    e.preventDefault();

    setDisabled(true);
    await createMessage({
      variables: {
        input: {
          userIds,
          message: {
            text,
          },
        },
      },
      update: (proxy, { data }) => {
        if (!data?.createMessage) {
          return;
        }

        const result = proxy.readQuery<MessagesQuery, MessagesQueryVariables>({
          query: MessagesDocument,
          variables: {
            input: {
              userIds,
              options: {
                limit: PAGE_SIZE,
                startToken: null,
              },
            },
          },
        });

        if (result) {
          proxy.writeQuery<MessagesQuery, MessagesQueryVariables>({
            query: MessagesDocument,
            variables: {
              input: {
                userIds,
                options: {
                  limit: PAGE_SIZE,
                  startToken: null,
                },
              },
            },
            data: {
              messages: {
                edges: [
                  { node: data.createMessage.message },
                  ...result.messages.edges,
                ],
                pageInfo: result.messages.pageInfo,
              },
            },
          });
        }
      },
    });
    setDisabled(false);

    setText("");
  };

  return (
    <AppBar
      component="footer"
      position="fixed"
      color="primary"
      className={classes.appBar}
    >
      <form onSubmit={sendMessage} className={classes.inputRow}>
        <InputBase
          disabled={disabled}
          value={text}
          onChange={(e) => setText(e.target.value)}
          fullWidth
          className={classes.textField}
          multiline
          // inputProps={{ className: classes.textInput }}
          placeholder="message"
        />
        <IconButton
          type="submit"
          disabled={disabled}
          className={classes.sendButton}
        >
          <Send className={classes.sendIcon} />
        </IconButton>
      </form>
    </AppBar>
  );
};

export default MessageInput;
