import React from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import LocationSearch from "components/LocationSearch";
import { useUsersInRadiusLazyQuery } from "queries/autogenerate/hooks";
import { DistanceUnit } from "queries/autogenerate/schemas";
import UserList from "components/UserList";
import LoadingScreen from "components/LoadingScreen";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(1.5),
  },
}));

const FindUsers: React.FunctionComponent = () => {
  const classes = useStyles();
  const [usersInRadiusQuery, { data, loading }] = useUsersInRadiusLazyQuery();

  const getUsersInRadius = (radius: number) => {
    navigator.geolocation.getCurrentPosition(async (position) => {
      usersInRadiusQuery({
        variables: {
          input: {
            coordinates: {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            },
            radius: {
              value: radius,
              unit: DistanceUnit.Miles,
            },
            options: {},
          },
        },
      });
    });
  };

  const users = data?.usersInRadius.edges.map(({ node }) => node);

  return (
    <Container className={classes.root}>
      <Typography variant="h4" className={classes.title}>
        Find Users
      </Typography>
      <LocationSearch min={1} max={100} unit="mi" onSubmit={getUsersInRadius} />
      {loading && <LoadingScreen />}
      {users && <UserList users={users} />}
    </Container>
  );
};

export default FindUsers;
