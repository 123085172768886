import { Button } from "@material-ui/core";
import {
  BlockButton_UserDocument,
  useBlockButton_UserQuery,
  useBlockUserMutation,
  useUnblockUserMutation,
} from "queries/autogenerate/hooks";
import {
  BlockButton_UserQuery,
  BlockButton_UserQueryVariables,
} from "queries/autogenerate/operations";
import { FunctionComponent } from "react";

export interface Props {
  username: string;
  className?: string;
}

const BlockButton: FunctionComponent<Props> = ({ username, className }) => {
  const { data } = useBlockButton_UserQuery({
    variables: {
      username,
    },
  });

  const [blockUserMutation] = useBlockUserMutation({
    variables: {
      input: {
        userId: username,
      },
    },
  });

  const [unblockUserMutation] = useUnblockUserMutation({
    variables: {
      input: {
        userId: username,
      },
    },
  });

  const blockUser = async () => {
    await blockUserMutation({
      update: (proxy, { data }) => {
        if (!data?.blockUser?.user) {
          return;
        }
        proxy.writeQuery<BlockButton_UserQuery, BlockButton_UserQueryVariables>(
          {
            query: BlockButton_UserDocument,
            variables: {
              username,
            },
            data: {
              user: data?.blockUser.user,
            },
          }
        );
      },
    });
  };

  const unblockUser = async () => {
    await unblockUserMutation({
      update: (proxy, { data }) => {
        if (!data?.unblockUser?.user) {
          return;
        }
        proxy.writeQuery<BlockButton_UserQuery, BlockButton_UserQueryVariables>(
          {
            query: BlockButton_UserDocument,
            variables: {
              username,
            },
            data: {
              user: data?.unblockUser.user,
            },
          }
        );
      },
    });
  };

  if (data?.user?.isBlocked) {
    return (
      <Button onClick={unblockUser} className={className} variant="contained">
        Unblock
      </Button>
    );
  }
  return (
    <Button onClick={blockUser} className={className} variant="contained">
      Block
    </Button>
  );
};

export default BlockButton;
