import { Typography } from "@material-ui/core";
import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { UserCard_UserFragment } from "queries/autogenerate/operations";
import UserCard from "components/UserCard";
import useDefaults from "styles/defaults";

export interface Props {
  users: UserCard_UserFragment[];
}

const UserList: FunctionComponent<Props> = ({ users }) => {
  const classes = useDefaults();

  if (!users.length) {
    return (
      <Typography className={classes.emptyText} align="center" variant="body2">
        Could not find any users.
      </Typography>
    );
  }

  return (
    <div>
      {users.map((user) => (
        <Link
          key={user.id}
          style={{ textDecoration: "none" }}
          to={`/users/${user.id}`}
        >
          <UserCard user={user} />
        </Link>
      ))}
    </div>
  );
};

export default UserList;
