import {
  FunctionComponent,
  useState,
  MouseEvent,
  useRef,
  useEffect,
} from "react";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: "relative",
  },
  menuButton: {
    position: "absolute",
    left: 0,
    top: 0,
    padding: theme.spacing(2),
    marginLeft: theme.spacing(0.5),
  },
  title: {
    flexGrow: 1,
    textAlign: "center",
  },
}));

const MenuBar: FunctionComponent = () => {
  const classes = useStyles();
  const headerRef = useRef<HTMLElement | null>(null);
  const [headerHeight, setHeaderHeight] = useState(0);

  useEffect(() => {
    setHeaderHeight(headerRef.current?.clientHeight || 0);
  }, [headerHeight]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <AppBar ref={headerRef} position="fixed">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={handleClick}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem component={Link} to="/me" onClick={handleClose}>
              Profile
            </MenuItem>
            <MenuItem component={Link} to="/users" onClick={handleClose}>
              Users
            </MenuItem>
            <MenuItem component={Link} to="/messages" onClick={handleClose}>
              Messages
            </MenuItem>
            <MenuItem component={Link} to="/signout" onClick={handleClose}>
              Sign Out
            </MenuItem>
          </Menu>
          <Typography variant="h6" className={classes.title}>
            SpeakEasy
          </Typography>
        </Toolbar>
      </AppBar>
      <Box style={{ height: headerHeight, minHeight: headerHeight }} />
    </>
  );
};

export default MenuBar;
