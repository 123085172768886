import {
  FormHelperTextProps,
  IconButton,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { FormEvent, FunctionComponent, useState } from "react";

const useStyles = makeStyles((theme) => ({
  searchBtn: {
    marginLeft: theme.spacing(1),
    padding: theme.spacing(0.5),
  },
  formRow: {
    display: "flex",
    alignItems: "flex-end",
    marginBottom: theme.spacing(3),
  },
}));

export interface Props {
  min: number;
  max: number;
  unit: "mi" | "km";
  onSubmit: (radius: number) => any;
}

const LocationSearch: FunctionComponent<Props> = ({
  min,
  max,
  unit,
  onSubmit,
}) => {
  const classes = useStyles();
  const [radius, setRadius] = useState<number | undefined>(undefined);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    onSubmit(radius!);
  };

  const inputWithinRange = () => {
    return !!(radius && radius <= max && radius >= min);
  };

  const errorStatus = () => {
    return !!radius && !inputWithinRange();
  };

  const formHelperTextProps: FormHelperTextProps = {
    style: { position: "absolute", top: "100%" },
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.formRow}>
        <TextField
          FormHelperTextProps={formHelperTextProps}
          onChange={(e) => setRadius(+e.target.value)}
          helperText={errorStatus() && `Must be between ${min} and ${max}`}
          error={errorStatus()}
          type="number"
          label={`Search Radius (${unit})`}
        />
        <IconButton
          type="submit"
          disabled={!inputWithinRange()}
          className={classes.searchBtn}
        >
          <Search />
        </IconButton>
      </div>
    </form>
  );
};

export default LocationSearch;
