import { useState, useEffect } from 'react'
import { Auth } from 'aws-amplify';

function useCurrentUsername() {
  const [unmounted, setUnmounted] = useState(false);
  const [username, setUsername] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      const user = await Auth.currentUserInfo();
      !unmounted && setUsername(user?.username);
    })()

    return () => {setUnmounted(true)}
  }, [unmounted]);

  return username;
}

export default useCurrentUsername;