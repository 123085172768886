import { Button, Container, makeStyles, Typography } from "@material-ui/core";
import { FunctionComponent } from "react";
import { Link, useParams } from "react-router-dom";
import LoadingScreen from "components/LoadingScreen";
import UserCard from "components/UserCard";
import { useGetUserQuery } from "queries/autogenerate/hooks";
import useDefaults from "styles/defaults";
import BlockButton from "components/BlockButton";

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(2),
  },
  messageButton: {
    marginRight: theme.spacing(2),
  },
}));

export interface NavigationParams {
  id: string;
}

const User: FunctionComponent = () => {
  const { id } = useParams<NavigationParams>();
  const classes = useStyles();
  const defaultClasses = useDefaults();
  const { data, loading } = useGetUserQuery({
    variables: {
      username: id,
    },
  });

  const user = data?.user;

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Container>
      {user ? (
        <div className={classes.card}>
          <UserCard user={user} />
          {!user.isBlocked && (
            <Button
              className={classes.messageButton}
              component={Link}
              to={`/messages/${user.id}`}
              variant="contained"
              color="primary"
            >
              Message
            </Button>
          )}
          <BlockButton username={user.id} />
        </div>
      ) : (
        <Typography className={defaultClasses.emptyText} align="center">
          This user does not exist
        </Typography>
      )}
    </Container>
  );
};

export default User;
