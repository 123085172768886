import React from "react";
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { Auth } from "aws-amplify";
import {
  MessageConnection,
  ConversationConnection,
} from "queries/autogenerate/schemas";

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_URL}/gateway/graphql`,
});

const authLink = setContext(async (_, { headers }) => {
  const session = await Auth.currentSession();
  const accessToken = session.getAccessToken().getJwtToken();

  return {
    headers: {
      ...headers,
      Authorization: accessToken,
    },
  };
});

interface Connection {
  pageInfo: {
    nextToken?: string | null | undefined;
  };
  edges: { node: Record<string, any> }[];
}

function mergePages<T extends Connection>(existing: T, incoming: T): T {
  // if the nextTokens are the same, this means we added an item to the cache manually
  // in that case, just return the new list since it will include previous items
  if (existing.pageInfo.nextToken === incoming.pageInfo.nextToken) {
    return incoming;
  }
  return {
    ...incoming,
    edges: [...existing.edges, ...incoming.edges],
  };
}

const client = new ApolloClient({
  link: ApolloLink.from([authLink, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          conversations: {
            keyArgs: false,
            merge(
              existing: ConversationConnection = {
                edges: [],
                pageInfo: { hasNextPage: false, nextToken: null },
              },
              incoming: ConversationConnection
            ) {
              return mergePages<ConversationConnection>(existing, incoming);
            },
          },
          messages: {
            keyArgs: (query) => {
              return JSON.stringify(query?.input.userIds);
            },
            merge(
              existing: MessageConnection = {
                edges: [],
                pageInfo: { hasNextPage: false, nextToken: null },
              },
              incoming: MessageConnection
            ) {
              return mergePages<MessageConnection>(existing, incoming);
            },
          },
        },
      },
    },
  }),
});

const Apollo: React.FunctionComponent = ({ children }) => (
  <ApolloProvider client={client}>{children}</ApolloProvider>
);

export default Apollo;
